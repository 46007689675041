*,
::after,
::before
{
	box-sizing: border-box;
}

*:focus-visible
{
	outline: unset;
}
