@font-face
{
  font-family: "Lora-Regular";
  src: url(assets/fonts/Lora-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face
{
  font-family: "Lora-Bold";
  font-display: swap;
  src: url(assets/fonts/Lora-Bold.ttf) format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face
{
  font-family: "Lora-Medium";
  font-display: swap;
  src: url(assets/fonts/Lora-Medium.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face
{
  font-family: "Lora-SemiBold";
  font-display: swap;
  src: url(assets/fonts/Lora-SemiBold.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face
{
  font-family: "Lora-MediumItalic";
  font-display: swap;
  src: url(assets/fonts/Lora-MediumItalic.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face
{
  font-family: "Lora-Italic";
  font-display: swap;
  src: url(assets/fonts/Lora-Italic.ttf);
  font-weight: normal;
  font-style: normal;
}

/* Open Sans */
@font-face
{
  font-family: "Open Sans";
  src: url("assets/fonts/OpenSans-Extrabold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face
{
  font-family: "Open Sans";
  src: url("assets/fonts/OpenSans-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face
{
  font-family: "Open Sans";
  src: url("assets/fonts/OpenSansLight-Italic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face
{
  font-family: "Open Sans";
  src: url("assets/fonts/OpenSans-ExtraboldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face
{
  font-family: "Open Sans";
  src: url("assets/fonts/OpenSans-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face
{
  font-family: "Open Sans";
  src: url("assets/fonts/OpenSans-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face
{
  font-family: "Open Sans";
  src: url("assets/fonts/OpenSans-Semibold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face
{
  font-family: "Open Sans";
  src: url("assets/fonts/OpenSans-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face
{
  font-family: "Open Sans";
  src: url("assets/fonts/OpenSans-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face
{
  font-family: "Open Sans";
  src: url("assets/fonts/OpenSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face
{
  font-family: "Open Sans";
  src: url("assets/fonts/OpenSans-SemiboldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

/* Helvetica Neue */
@font-face
{
  font-family: "HelveticaNeue";
  src: url("assets/fonts/HelveticaNeue-Regular.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face
{
  font-family: "Satisfy";
  src: url(assets/fonts/Satisfy-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

.inputStyle
{
  background-color: #fff;
  border: none;
  border-radius: 10px;
  padding: 15px;
  font-size: 18px;
  font-family: "Open Sans";
  color: #4d4d4d;
  outline: none;
}

.caption
{
  display: none;
}

.scale
{
  fill: transparent !important;
  stroke: #fff !important;
  stroke-width: 0.8 !important;
}

.axis
{
  stroke: #fff !important;
  stroke-width: 0.5 !important;
}

.shape
{
  fill-opacity: 0.8 !important;
  fill: #fff;
}

.textecotationLinks
{
  text-decoration: none;
}

@media (max-width: 1700px)
{
  .marginBottomd
  {
    margin-bottom: 20px !important;
  }

  .heightDesc
  {
    height: 100% !important;
  }
}

@media (max-width: 1096px)
{
  .marginBottomd
  {
    margin-bottom: 0px !important;
  }
}

@media (max-width: 1400px)
{
  .share
  {
    width: 350px !important;
  }

  .share img
  {
    width: 30px;
  }

  .share p
  {
    font-size: 18px;
  }
}

@media (max-width: 1650px)
{
  .h1-status
  {
    font-size: 35px !important;
  }
}

@media (max-width: 1536px)
{
  .h1-status
  {
    font-size: 28px !important;
  }
}

@media (max-width: 1350px)
{
  .p-status
  {
    font-size: 16px !important;
  }
}

@media (max-width: 1786px)
{
  .imgDetails img
  {
    width: 80px !important;
  }

  .headDetails
  {
    font-size: 35px !important;
  }
}

@media (max-width: 1500px)
{
  .heighSteper
  {
    height: 100% !important;
  }

  .heightDetails
  {
    height: 100% !important;
  }

  .heightDesc
  {
    height: 100% !important;
  }

  .displayShared
  {
    display: flex !important;
  }

  .displaySharedNone
  {
    display: none !important;
  }
}

@media (max-width: 700px)
{
  .heighSteperFirst
  {
    height: 100% !important;
  }

  .pb0
  {
    padding-bottom: 0px !important;
  }
}

@media (max-width: 1200px)
{
  .displayShared
  {
    display: none !important;
  }
}

@media (max-width: 1750px)
{
  .imageWelcom
  {
    max-width: 1000px !important;
  }

  .octaWelcomFirst img
  {
    width: 180px;
  }

  .octaWelcomFirst
  {
    top: -292px !important;
    left: 200px !important;
  }
}

@media (max-width: 1500px)
{
  .octaWelcomFirst
  {
    left: 67px !important;
  }

  .octaWelcomSecond img
  {
    width: 350px !important;
  }

  .octaWelcomSecond
  {
    bottom: -315px !important;
  }
}

@media (max-width: 1377px)
{
  .imageWelcom
  {
    max-width: 800px !important;
  }

  .octaWelcomFirst
  {
    top: -253px !important;
    left: 162px !important;
  }

  .paddingDescWelcom
  {
    padding: 40px 50px 40px 80px !important;
  }
}

::-webkit-scrollbar
{
  background-color: transparent;
  width: 0px;
}

.scrollAdmin::-webkit-scrollbar
{
  width: 10px;
  cursor: pointer;
}

.scrollCustom::-webkit-scrollbar
{
  width: 10px;
  cursor: pointer;
}

.scrollAdmin::-webkit-scrollbar-thumb
{
  background-color: rgba(33, 92, 117, 0.2);
  border-radius: 30px;
  cursor: pointer;
}

.scrollCustom::-webkit-scrollbar-thumb
{
  background-color: rgba(33, 92, 117, 0.2);
  border-radius: 30px;
  cursor: pointer;
}

.textDecorNone
{
  text-decoration: none;
}

@media (max-width: 1700px)
{
  .octaDetailsSmall
  {
    display: none !important;
  }
}

@media (max-width: 1578px)
{
  .displayImageDetails
  {
    display: none !important;
  }

  .heightetails
  {
    height: 100% !important;
    border-radius: 60px 0 60px 60px !important;
  }
}

@media (max-width: 1310px)
{
  .withSmallDesc
  {
    max-width: 99% !important;
    flex-basis: 99% !important;
    margin: 0 auto !important;
  }

  .heightetails
  {
    border-radius: 0px 0 60px 60px !important;
  }

  .marginCenter
  {
    margin: 0 auto;
  }

  .withSmallGraph
  {
    max-width: 100% !important;
    flex-basis: 100% !important;
  }

  .heightDesc
  {
    border-radius: 60px 60px 0 0 !important;
    margin-top: 40px;
    padding: 0 40px !important;
  }
}

@media (max-width: 500px)
{
  .heightDesc
  {
    border-radius: 0 !important;
    margin-top: 40px;
    padding: 0 40px !important;
  }
}

.css-l2rnec-MuiSlider-valueLabel:before
{
  width: 12px !important;
  height: 12px !important;
  background-color: rgba(255, 255, 255, 0.6) !important;
  display: none !important;
}

.linkdetails
{
  text-decoration: none;
  color: #000;
}

.markerStyle li
{
  font-family: "Open sans";
  font-size: 22px;
  color: #4d4d4d;
}

@media (max-width: 1536px)
{
  .markerStyle li
  {
    font-size: 16px;
  }
}

.animation--blip-brightness
{
  --duration: 1s;
  animation: blip-brightness var(--duration) infinite;
  /* animation: linear blip-brightness var(--duration) infinite; */
}

.animation--blip-contrast
{
  --duration: 1s;
  animation: blip-contrast var(--duration) infinite;
  /* animation: linear blip-contrast var(--duration) infinite;  */
}

.animation--blip-radialButton
{
  --duration: 1s;
  animation: ease-in-out blip-radialButton-scale var(--duration) infinite;
}

/* .animation--blip-radialButton *
{
	--duration: 1s;
	animation: ease-in-out blip-radialButton-color var(--duration) infinite;
} */

.animation--blip-bulb
{
  --duration: 1s;
  animation: blip-bulb var(--duration) infinite;
  /* animation: linear blip-contrast var(--duration) infinite;  */
}

.ghost-white svg path{
  fill: #ffffff !important;
}

/* .animation--blip--no svg path{ */
.ghost-blue svg path{
  fill: #215C75 !important;
}

@keyframes blip-brightness
{

  0%,
  100%
  {
    filter: brightness(none) contrast(none);
  }

  50%
  {
    filter: brightness(1.5) contrast(0.8);
  }
}

@keyframes blip-contrast
{

  0%,
  100%
  {
    filter: contrast(none);
  }

  50%
  {
    filter: contrast(1.5);
  }
}

@keyframes blip-bulb
{

  0%,
  100%
  {
    transform: scale(1);
    filter: contrast(none);
  }

  50%
  {
    transform: scale(1.15);
    filter: contrast(0.8) saturate(4);
  }
}

@keyframes blip-radialButton-color
{

  0%,
  100%
  {
    stroke: #ffffff;
  }

  50%
  {
    stroke: rgb(173, 245, 250);
  }
}

@keyframes blip-radialButton-scale
{

  0%,
  100%
  {
    transform: scale(1);
    filter: saturate(1);
  }

  50%
  {
    transform: scale(1.15);
    filter: saturate(5);
  }
}

.hide-arrow
{
  visibility: hidden;
}

.visible-arrow
{
  visibility: visible;
}

.fill-current
{
  fill: #3e809d;
}

.back-to-top
{
  position: fixed;
  bottom: 24px;
  right: 4px;
  cursor: pointer;
  z-index: 10;
}

.timeBox
{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  min-width: 70px;
  height: 56px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  margin-right: 15px;
  position: relative;
  padding-right: 10px;
}

.arrayTop,
.arrayBottom
{
  width: 12px;
  height: 10px;
  position: absolute;
  cursor: pointer;
}

.arrayTop
{
  top: 6px;
  right: 7px;
  background-image: url(../src/assets/images/missions/mission-five/arrow-top.png);
}

.arrayBottom
{
  bottom: 6px;
  right: 7px;
  background-image: url(../src/assets/images/missions/mission-five/arrow-bottom.png);
}

.customScroll::-webkit-scrollbar
{
  width: 8px;
  cursor: pointer;
  background-color: rgba(62, 128, 157, 0.3);
  border-radius: 8px;
}

.customScroll::-webkit-scrollbar-thumb
{
  background-color: rgba(62, 128, 157, 0.5);
  border-radius: 8px;
}

.customScrollWhite::-webkit-scrollbar
{
  width: 8px;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 8px;
}

.customScrollWhite::-webkit-scrollbar-thumb
{
  background-color: #FFFFFF;
  border-radius: 8px;
}

.radialChart
{
  background: linear-gradient(135.61deg, rgba(217, 234, 255, 0.64) 0%, rgba(221, 233, 248, 0.32) 98.96%);
}

.backdropFilter
{
  background: linear-gradient(135.61deg,
      rgba(90, 151, 225, 0.24) 0%,
      rgba(62, 128, 157, 0.12) 98.96%) !important;
}

.morningRadial:before
{
  content: "";
  position: absolute;
  width: 119%;
  height: 143%;
  top: -26%;
  left: 7%;
  z-index: -1;
  background: url(assets/images/morningRadial.png) center center/cover;
  transform: rotate(-45deg);
}

.afternoonRadial:before
{
  content: "";
  position: absolute;
  width: 110%;
  height: 126%;
  top: -5%;
  left: 3%;
  z-index: -1;
  background: url(assets/images/clockAfternoon.png) center center/cover;
  transform: rotate(-135deg);
}

.radialEvening:before
{
  content: "";
  position: absolute;
  width: 89%;
  height: 146%;
  top: -45%;
  left: 39%;
  z-index: -1;
  background: url(assets/images/radialEvening.png) center center/cover;
  transform: rotate(135deg);
}

.radialNight:before
{
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 15%;
  left: -25%;
  z-index: -1;
  background: url(assets/images/radialNight.png) center center/cover;
  transform: rotate(225deg);
}

.bgGr
{
  box-shadow: 0px 4px 6px rgba(73, 140, 168, 0.3);
  backdrop-filter: blur(85px);
  background: linear-gradient(135.61deg,
      rgba(217, 234, 255, 0.64) 0%,
      rgba(221, 233, 248, 0.32) 98.96%);
}

.bgTooltip
{
  background: linear-gradient(to top, rgba(239, 239, 239, .6) 10%, rgba(239, 239, 239, .1)) !important;
}

.gradientBg
{
  background: linear-gradient(135.61deg, rgba(217, 234, 255, 0.14) 0%, rgba(221, 233, 248, 0.52) 98.96%);
}

.itemBox {
  font: 400 22px/30px "Open Sans";
}
.formBg {
  background: linear-gradient(135.61deg, rgba(239, 239, 239, 0.64) 0%, rgba(239, 239, 239, 0.0816));
}


.liked svg{
  opacity: 0;
}

.missions-dialog-list ol {
  position: relative;
  top:-10px;
  left:-14px;
}
