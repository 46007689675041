*,
::after,
::before
{
	box-sizing: border-box;
}

.reactour__mask
{
	color: rgba(96, 131, 178, 0.6);
}

.reactour__helper
{
	display: flex;
	align-items: center;
	flex-direction: column;

	/* reset */
	min-width: unset !important;
	max-width: unset !important;
	background: unset !important;
	padding: unset !important;
	box-shadow: unset !important;
	padding-right: 50px !important;
	padding-left: 50px !important;
}

.reactour__close
{
	position: unset !important;
	width: fit-content !important;
	height: unset !important;

	margin-top: 4px;
}

.reactour__close::before
{
	content: "skip";
	text-align: center;

	color: #4D4D4D;
	text-decoration: underline;
}

@media (min-width: 0px)
{
	.reactour__close::before
	{
		font: 400 16px/22px 'Open Sans';
	}
}

@media (min-width: 1700px)
{
	.reactour__close::before
	{
		font: 400 22px/30px 'Open Sans';
	}
}

.reactour__close svg
{
	display: none !important;
}

div[data-tour-elem="controls"]
{
	margin-top: 16px;
	width: 100%;
}

div[data-tour-elem="controls"] > button
{
	margin: unset;
	width: 100%;
}

div[data-tour-elem="controls"] > button button
{
	width: 100%;
}

div[data-tour-elem="controls"] button[data-tour-elem='left-arrow']
{
	display: none;
}

@media (min-width: 320px) and (max-width: 600px)
{
	#___reactour
	{
		display: none;
	}
}
