.animationVibrate
{
	--delay: 0ms;
	animation: linear 1000ms animation infinite var(--delay);
}

@keyframes animation
{
	0%
	{
		transform: rotateZ(0deg);
	}

	25%
	{
		transform: rotateZ(5deg);
	}

	50%
	{
		transform: rotateZ(0deg);
	}

	75%
	{
		transform: rotateZ(-5deg);
	}

	100%
	{
		transform: rotateZ(0deg);
	}
}
